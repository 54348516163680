import { useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { SiteDetail } from 'api/site';
import { useTranslation } from 'react-i18next';
import { ChangeLabelTooltip } from 'component/partial/ChangeLabelTooltip';
import { SiteList } from 'component/partial/SiteList';
import { TicketsList } from './TicketsList';

export function Tickets({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const [siteToChangeLabel, setSiteToChangeLabel] = useState<SiteDetail | null>(null);
  const { t } = useTranslation();

  return (
    <main id="mainContent">
      {siteToChangeLabel ? (
        <ChangeLabelTooltip
          key={siteToChangeLabel.id ?? 'none'}
          site={siteToChangeLabel}
          onClose={() => setSiteToChangeLabel(null)}
        />
      ) : null}
      <Container maxWidth="lg">
        <Box marginBottom={3}>
          <Typography variant="h1" data-testid="domainLabel">
            {t('my_tickets')}
          </Typography>
        </Box>
        <TicketsList isWpSiteControl={isWpSiteControl} />
      </Container>
    </main>
  );
}
