import { useContext } from 'react';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Button,
  Typography,
  Card,
  CardContent,
  SelectChangeEvent,
  Chip,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleUp } from '@fortawesome/pro-regular-svg-icons';

export default function RowSkeleton({ showAtarimCell }: { readonly showAtarimCell: boolean }) {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

type Ticket = {
  id: string;
  title: string;
  status: string;
};

export function TicketsList({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const tickets: Ticket[] = [];

  const renderTableRows = () => {
    return tickets.map(ticket => {
      return (
        <TableRow key={ticket.id}>
          <TableCell>{ticket.title}</TableCell>
          <TableCell>
            <Chip label={ticket.status} />
          </TableCell>
          <TableCell>
            <FontAwesomeIcon icon={faAngleRight} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Card>
      <CardContent>
        <TableContainer>
          <Table aria-label="Tickets List Table">
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
